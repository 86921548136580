import { API } from "../../global";

export const START_CREATE_PROJECT = "START_CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";

export const startProjectCreate = () => ({
  type: START_CREATE_PROJECT,
});

export const crateProjectSuccess = (data) => ({
  type: CREATE_PROJECT_SUCCESS,
  payload: data,
});

export const createProjectError = (error) => ({
  type: CREATE_PROJECT_ERROR,
  payload: error,
});

export const startConversation = (payload) => async (dispatch) => {
  dispatch(startProjectCreate()); // Dispatch the start action
  try {
    const response = await fetch(`${API}/chat/start-conversation`, {
      method: "POST", // Specify the HTTP method (e.g., POST, PUT, DELETE, etc.)
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(crateProjectSuccess(data)); // Dispatch the success action with payload
    return data
  } catch (error) {
    dispatch(createProjectError(error.toString())); // Dispatch the error action with payload
  }
};
