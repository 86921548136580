import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableRow } from "@mui/material";
import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledCheckbox,
} from "../ActionItStyles";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const ActivityTable = ({ height }) => {
  const [activities, setActivities] = useState([]);
  const { showToast } = useToast();
  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = () => {
    fetch(`${API}/project/tasks`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ task_type: "email" }),
    })
      .then((response) => response.json())
      .then((res) => {
        let activityList = [];
        res.result.forEach((data) => {
          const obj = {
            _id: data._id,
            projectId: data?.project_id,
            taskAction: data?.task_details?.name,
            companyName: data?.company_details?.company_name,
            contactName: data?.contact_details?.name,
            contactEmail: data?.company_details?.contact_email,
            status: data.task_status === "pending" ? false : true,
          };
          activityList.push(obj);
        });

        setActivities(activityList);
      })
      .catch((error) => {});
  };

  const onChangeStatus = (e, activity, index) => {
    // if (index >= 0 && index < activities.length) {
    //   activities[index].status = e.target.value;
    // } else {
    //   activities[index].status = false
    // }

    // const statusValue = e.target.checked ? " sent" : "pending";

    fetch(`${API}/project/task-update-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        task_id: activity._id,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        getActivities();
        showToast("success", "Status updated successfully");
      })
      .catch(() => {});
  };

  return (
    <>
      <StyledTableContainer
        sx={{
          // height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Table Head (Static) */}
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell
                sx={{ backgroundColor: "#8348CF1A", width: "40%" }}
              >
                Contact
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ backgroundColor: "#8348CF1A", width: "40%" }}
              >
                Action
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ backgroundColor: "#8348CF1A", width: "40%" }}
              >
                Status
              </StyledTableCell>
            </TableRow>
          </StyledTableHead>
        </Table>

        {/* Scrollable Table Body */}
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: `${height - 92}px`,
            //Hieght for Display scaled at 125%
            "@media (min-resolution: 120dpi)": {
              height: `${height - 280}px`,
            },
            //Hieght for Display scaled at 150%
            "@media (min-resolution: 144dpi)": {
              height: `${height-405}px`,
            },
          }}
        >
          <Table>
            <TableBody>
              {activities.length !== 0 ? (
                activities.map((activity, index) => (
                  <TableRow key={index}>
                    <StyledTableCell sx={{ width: "40%" }}>
                      {activity.contactName} from {activity.companyName}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "40%" }}>
                      {activity.taskAction}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "40%" }}>
                      <StyledCheckbox
                        checked={activity.status}
                        onChange={(e) => onChangeStatus(e, activity)}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={3} align="center">
                    No Data Found!
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </StyledTableContainer>
    </>
  );
};

export default ActivityTable;
