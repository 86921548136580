import { Box, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";

const InitialBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "34vh",
  color: "#9CA4BA",
  backgroundColor: "white",
});

const SalesMainBox = styled(Box)({
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  width: "100%",
});

const ProgressBox = styled(Box)({
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
});

const SummaryBox = styled(Box)({
  height: "100%", // Inner box height relative to the outer box
  overflowY: "auto", // Enable vertical scrolling
  overflowX: "hidden",
  borderRadius: "5px",
});

const StatusBox = styled(Box)(({ theme }) => ({
  flex: 1,
  height: "120px",
  padding: "10px",
  borderRadius: "5px",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: " 0px 0px 6px #5593FE1A",
  border: " 1px solid #5593FE33",
  display: "flex",
  flexDirection: "column",
  gap: "3px",
  justifyContent: "space-between",
  alignItems: "center",

   //Hieght for Display scaled at 125%
   "@media (min-resolution: 120dpi)": {
    height: "100px",
    marginBottom:"20px"
    
  },
  //Hieght for Display scaled at 150%
  "@media (min-resolution: 144dpi)": {
    height: "65px",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#9CA4BA",
  width: "100%",
  height: "100%",
  fontSize: "12px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const LabelBox = styled(Box)(({ theme }) => ({
  color: "#868A97",
  fontSize: "12px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const ProgressStatus = styled(LinearProgress)(({ theme, progressColor }) => ({
  height: "12px",
  backgroundColor: "#D7DAE3",
  borderRadius: "2px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: progressColor || "#5593FE", // Progress color
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const BarContainer = styled(Box)({
  width: "90%",
  marginLeft: "10px",
  border: "1px solid #D7DAE3",
  borderRadius: "2px",
  padding: "1px",
});

const ProgressCount = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  color: "#0D162F",
  fontWeight: 500,
  [theme.breakpoints.up("xl")]: {
    fontSize: "22px",
  },
}));

const CountContainer = styled(Box)(({ theme }) => ({
  minWidth: "35px",
  marginBottom: "2px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "22px",
    marginBottom: "5px",
  },
}));

const CustomTabContent = styled(TabPanel)({
  padding: "0px",
  color: "#707070",
  fontSize: "14px",
  lineHeight: "24px",
});

const CustomTabsList = styled(TabList)({
  minHeight: "10%",
  "& .css-1qltlow-MuiTabs-indicator": {
    backgroundColor: "transparent", // Removes the background color
  },
});

const CustomTab = styled(Tab)(({ selected }) => ({
  padding: "5px 10px",
  minHeight: "10%",
  backgroundColor: selected ? "#009872ED" : "#F1F1F1", // Change color for active tab
  color: selected ? "white" : "#868A97", // Change font color for active tab
  fontSize: "14px",
  borderBottom: "none",
  textTransform: "capitalize",
  borderRadius: "5px 5px 0 0px",
  borderColor: selected ? " #009872ED" : "#DDDEE0",
  borderStyle: "solid",
  borderWidth: "1px 1px 0px 1px",

  "&.Mui-selected ": {
    color: selected ? "white" : "#868A97",
  },
}));

const CustomTabBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: " 0px 0px 6px #5593FE1A",
  border: " 1px solid #5593FE33",
  borderRadius: " 0px 5px 5px 5px",
  height: "36vh",
  padding: "20px",
  overflowY: "auto", // Enable vertical scrolling
  overflowX: "hidden",
  // [theme.breakpoints.up("xl")]: {
  //   height: "82%",
  // },
  //Hieght for Display scaled at 125%
  "@media (min-resolution: 120dpi)": {
    height: "26.5vh",
    
  },
  //Hieght for Display scaled at 150%
  "@media (min-resolution: 144dpi)": {
    height: "20vh",
  },
}));

const ImageContainer = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
  [theme.breakpoints.up("xl")]: {
    width: "78px",
    height: "78px",
  },
}));

const LoadingBox = styled("span")(({ theme }) => ({
  position: "absolute",
  top: "19px",
  left: "10px",
  fontSize: "8px",
  color: "#FFFFFF",
  [theme.breakpoints.up("xl")]: {
    fontSize: "8px",
    top: "30px",
    left: "15px",
  },
}));

export {
  InitialBox,
  SalesMainBox,
  ProgressBox,
  SummaryBox,
  StatusBox,
  LabelBox,
  ContentBox,
  ProgressStatus,
  BarContainer,
  ProgressCount,
  CountContainer,
  CustomTabContent,
  CustomTab,
  CustomTabsList,
  CustomTabBox,
  ImageContainer,
  LoadingBox,
};
