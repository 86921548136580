import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { ThemeProvider } from "@emotion/react";
import { theme } from "./Reusable/Theme";

import reportWebVitals from "./reportWebVitals";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import AuthLayout from "./components/Authentication/AuthLayout/AuthLayout";
import Login from "./components/Authentication/Login/Login";
import Register from "./components/Authentication/Register/Register";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./Redux/store"; // Import your Redux store and persistor

import { AuthProvider } from "./components/Authentication/AuthContext/AuthContext";
// import PlanIt from "./components/PlanIt/PlanIt";
import ProtectedRoute from "./components/Authentication/AuthContext/ProtectedRoute";

import { ToastProvider } from "./utils/ToastContext";
import CustomSnackbar from "./Reusable/CustomSnackBar/CustomSnacker";
import DashboardPlanIt from "./components/Dashboard/DashboardPlanIt";
import Dashboard from "./components/Dashboard/Dashbaord";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Authentication/ResetPassword/ResetPassword";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import SdrDashboard from "./components/Dashboard/SdrDashboard/SdrDashboard";
import EULAPage from "./components/Authentication/Register/EULA";
import UnauthorizedPage from "./RouteConfig/UnauthorizedPage";
// import ActionPage from "./components/ActionIt/ActionPage";
// import ActionIt from "./components/ActionIt/ActionIt";
// import PlanPage from "./components/PlanIt/PlanPage";
import MainLayout from "./components/Common/Chat/MainLayout";
import ActionItLayout from "./components/Common/Chat/ActionItLayout";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* <Route path="/piqual-ai" element={<Layout />}>
        <Route
          path="dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="dashboard/plan-it"
          element={<ProtectedRoute element={<DashboardPlanIt />} />}
        />
        <Route
          path="plan-it"
          element={<ProtectedRoute element={<PlanIt />} />}
        />
        <Route
          path="account-settings"
          element={<ProtectedRoute element={<ProfilePage />} />}
        />
        <Route
          path="sdr-dashboard"
          element={<ProtectedRoute element={<SdrDashboard />} />}
        />
        <Route
          path="action-it"
          element={<ProtectedRoute element={<ActionIt />} />}
        />
        <Route path="error" element={<UnauthorizedPage/>} />

      </Route> */}
      <Route path="/piqual-ai" element={<Layout />}>
        <Route
          path="dashboard"
          element={
            <ProtectedRoute
              element={<Dashboard />}
              allowedRoles={["piqai_admin"]}
            />
          }
        />
        <Route
          path="dashboard/plan-it"
          element={
            <ProtectedRoute
              element={<DashboardPlanIt />}
              allowedRoles={["piqai_admin"]}
            />
          }
        />
        <Route
          path="plan-it"
          element={
            <ProtectedRoute
              element={<MainLayout />}
              allowedRoles={["piqai_admin"]}
            />
          }
        />
        <Route
          path="account-settings"
          element={
            <ProtectedRoute element={<ProfilePage />} allowedRoles={["piqai_admin", "piqai_sdr"]} />
          }
        />
        <Route
          path="sdr-dashboard"
          element={
            <ProtectedRoute element={<SdrDashboard />} allowedRoles={["piqai_sdr"]} />
          }
        />
        <Route
          path="action-it"
          element={
            <ProtectedRoute
              element={<ActionItLayout />}
              allowedRoles={["piqai_sdr"]}
            />
          }
        />
        <Route path="error" element={<UnauthorizedPage />} />
      </Route>
      <Route path="/" element={<AuthLayout />}>
        <Route path="" element={<Login />} />
        <Route path="sign-up/:id/:token" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:id/:token" element={<ResetPassword/>} />
        <Route path="agreement/:token" element={<EULAPage />} />
      </Route>
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <PersistGate loading={null} persistor={persistor}>
        {" "}
        {/* Add PersistGate */}
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <RouterProvider router={router} />
            <CustomSnackbar />
          </ToastProvider>
        </ThemeProvider>
      </PersistGate>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
