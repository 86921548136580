import React, { useEffect, useState } from "react";
import { Box,  useMediaQuery, useTheme } from "@mui/material";
// import { ContentBox, StatusBox, TitleBox } from "../../Dashboard/DashboardStyles";
import {
  ContentBox,
  CustomTab,
  CustomTabBox,
  CustomTabContent,
  CustomTabsList,
  InitialBox,
  LoadingBox,
  ProgressBox,
  StatusBox,
  ImageContainer
} from "../../PlanIt/SalesPlan/SalesPlanStyles";
import PresenceScore from "../../PlanIt/SalesPlan/PresenceScore";
import StatusProgressbar from "../../PlanIt/SalesPlan/StatusProgressbar";
import { LabelBox } from "../../PlanIt/PlanItStyles";
import { TitleBox } from "../../ActionIt/ActionItStyles";
// import { ImageContainer } from "../../Authentication/AuthLayout/AuthLayoutStyles";
import { gtmIcon } from "../../../Constants/Constant";
import { TabContext } from "@mui/lab";
import { useSelector } from "react-redux";

const SalesPlanBox = () => {
  //   const [initialBox, setInitialBox] = useState(true);
  const theme = useTheme();
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const projectData = useSelector(
    (state) => state.projectDetails.projectDetails
  );
  const metricsDetails = useSelector(
    (state) => state.projectDetails.metricsDetails
  );

  // let loading = false;
  // const [hightLight, setHighLight] = useState(false);

  // const [clientList, setClientList] = useState("");

  const chatData = useSelector((state) => state.companyDetails.aiChatDetails);
  const companyData = chatData;

  // const [prevChatData, setPrevChatData] = useState(chatData);
  // const [prevCompanyData, setPrevCompanyData] = useState(companyData);

  //   const inputData = localStorage.getItem("inputKey");
  // const loadingData = JSON.parse(localStorage.getItem("loading"));
  useEffect(() => {
    if (chatData?.question_category === "about_company") {
      setValue("1");
    }

    if (projectData?.clientele && chatData?.question_category === "clientele") {
      setValue("2");
    }

    if (
      projectData?.target_geographies &&
      chatData?.question_category === "target_geographies"
    ) {
      setValue("3");
    }
    if (
      projectData?.target_industries &&
      chatData?.question_category === "target_industries"
    ) {
      setValue("4");
    }
    if (
      projectData?.prospect_companies?.length !== 0 &&
      (chatData?.question_category === "prospect_companies" ||
        chatData?.question_category === "target_revenue_range")
    ) {
      setValue("5");
    }

    // setClientList(chatData?.summary?.Clientele);
    // eslint-disable-next-line
  }, [chatData, projectData]);
  //   useEffect(() => {
  //     if (inputData === "added") {
  //       setInitialBox(false);
  //     }
  //   }, [inputData]);

  useEffect(() => {
    //commenting for future
    // if (chatData?.category) {
    //   setHighLight(true); // Highlight or any other action
    // }

    //commenting for future
    // if (prevCompanyData !== companyData) {
    //   setHighLight(true); // Highlight or any other action
    // }

    // Update previous values
    // setPrevChatData(chatData);
    // setPrevCompanyData(companyData);

    // Optional: Reset highlight after a timeout
    const timeout = setTimeout(() => {
      // setHighLight(false);
    }, 3000); // Resets highlight after 3 seconds

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [chatData, companyData]);

  // const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // commenting for future
  // useEffect(() => {
  //   const handleResize = () => {
  //     setViewportHeight(window.innerHeight); // Dynamically adjust height
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  // useEffect(() => {

  //   if (!chatData?.question_category) {
  //     setLoading(false);
  //   }else{
  //     setLoading(loadingData);
  //   }
  // }, [loadingData,chatData]);
  const [value, setValue] = useState("1");
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {/* Heading */}
      <TitleBox mb={1}>Your Sales Plan So Far</TitleBox>

      {/* Inner Box 1 */}
      <Box
        sx={{
          //   background: "#56BB6B",
          height: "calc(30% - 2px)", // Adjust height to accommodate heading
          marginBottom: "4px", // Gap between Inner Box 1 and Inner Box 2
          borderRadius: "4px",
        }}
      >
        <ProgressBox>
          <StatusBox>
            <ContentBox>
              {/* {loading ? <Box>Loading...</Box> : <StatusProgressbar status={metricsDetails?.overall_process_status} />} */}
              <StatusProgressbar
                status={metricsDetails?.overall_process_status}
              />
            </ContentBox>
            <LabelBox>Overall Process Status</LabelBox>
          </StatusBox>
          <StatusBox>
            <ContentBox>
              <Box sx={{ height: "100%", width: "100%" }}>
                <PresenceScore score={metricsDetails?.online_presence_score} />
              </Box>
            </ContentBox>
            <LabelBox>Online Presence Score</LabelBox>
          </StatusBox>
          <StatusBox>
            
            <ContentBox>
              <Box sx={{ position: "relative" }}>
                <ImageContainer src={gtmIcon} alt="" />
                <LoadingBox>
                  {isXLScreen ? "Waiting for the data" : "Loading..."}
                </LoadingBox>
              </Box>
            </ContentBox>
            <LabelBox>Go-To-Market Readiness</LabelBox>
          </StatusBox>
        </ProgressBox>
      </Box>

      {/* Inner Box 2 */}
      {projectData ? (
        <Box
          sx={{
            height: "calc(70% - 1px)", // Half of SD's height minus gap
            borderRadius: "5px",
          }}
        >
          <TabContext value={value} sx={{ height: "100%" }}>
            <Box>
              <CustomTabsList
                onChange={(event, newValue) => handleChange(newValue)}
                aria-label="lab API tabs example"
                value={value}
              >
                {projectData?.about_company && (
                  <CustomTab label="About Company" value="1" />
                )}
                {projectData?.clientele && (
                  <CustomTab label="Clientele" value="2" />
                )}
                {projectData?.target_geographies && (
                  <CustomTab label="Geography" value="3" />
                )}
                {projectData?.target_industries && (
                  <CustomTab label="Industry" value="4" />
                )}
                {projectData?.prospect_companies &&
                  projectData?.prospect_companies.length !== 0 && (
                    <CustomTab label="Prospect Companies" value="5" />
                  )}
              </CustomTabsList>
            </Box>
            <CustomTabBox>
              <CustomTabContent value="1">
                {projectData?.about_company}
              </CustomTabContent>
              <CustomTabContent value="2">
                <ol>
                  {projectData?.clientele?.split(",")?.map((data) => (
                    <li>{data}</li>
                  ))}
                </ol>
              </CustomTabContent>
              <CustomTabContent value="3">
                <ol>{projectData?.target_geographies}</ol>
              </CustomTabContent>
              <CustomTabContent value="4">
                <ol>{projectData?.target_industries.split(',')?.map((data)=>(
                   <li>{data}</li>
                ))}</ol>
              </CustomTabContent>
              <CustomTabContent value="5">
                <ol>
                  {projectData?.prospect_companies?.map((data) => (
                    <li>{data.company_name}</li>
                  ))}
                </ol>
              </CustomTabContent>
            </CustomTabBox>
          </TabContext>
        </Box>
      ) : (
        <InitialBox
          sx={{
            height: "50vh",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: " 0px 0px 6px #5593FE1A",
            border: " 1px solid #5593FE33",
          }}
        >
          Waiting for data...
        </InitialBox>
      )}
    </Box>
  );
};

export default SalesPlanBox;
