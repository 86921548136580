import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import {
  AuthButton,
  ErrorMsg,
  FormContainer,
  FormFieldsWrapper,
  FormInput,
  PasswordInput,
  PasswordToggleIcon,
} from "../../../Common/CommonStyles";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../global";
import { useDispatch } from "react-redux";
import { setSdrUserData } from "../../../Redux/Actions/sdrRegisterAction";


const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is a required field.")
    .matches(/^[A-Za-z]+$/, "First name must contain only alphabets."),
  lastName: yup
    .string()
    .required("Last name is a required field.")
    .matches(/^[A-Za-z]+$/, "Last name must contain only alphabets."),
  password: yup
    .string()
    .required("Password is a required field.")
    .min(8, "Password must be at least 8 characters.")
    .max(20, "Password must be at most 20 characters.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/\d/, "Password must contain at least one numeric digit.")
    .matches(
      /[.#@!%^&*?_()\-+=]/,
      "Password must contain at least one special character (., @, #, !, %, &, *, ?, ^, (, ), _, -, =, +)."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Password is a required field."),
});

const Register = () => {

  const { id, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState('')

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [loading,setLoading] = useState(false)
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getUserData()

    // eslint-disable-next-line 
  }, [])

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const getUserData = () => {
    fetch(`${API}/auth/get-sdr-details-by-key`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ verify_account_key: id }),
    })
      .then((response) => response.json())
      .then((res) => {
        const userData = res.result;
        console.log("userData._id", userData._id)
        setUserId(userData._id)
        setValue('email', userData.email);
      })
      .catch((error) => {

      });
  }



  const onSubmit = (data) => {
    data.verify_account_key = id
    data.user_id = userId
    dispatch(setSdrUserData(data))

    navigate(`/agreement/${token}`)
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsWrapper>
        {/* <FormInput
          sx={{ width: "94.5%" }}
          {...register("email")}
          placeholder="Enter Email Address"
          disabled
        /> */}
        <FormInput
          sx={{ width: "94.5%" }}
          {...register("firstName")}
          placeholder="Enter First Name"
        />
        <ErrorMsg variant="p">{errors?.firstName?.message}</ErrorMsg>
        <FormInput
          sx={{ width: "94.5%" }}
          {...register("lastName")}
          placeholder="Enter Last Name"
        />
        <ErrorMsg variant="p">{errors?.lastName?.message}</ErrorMsg>
        <PasswordInput>
          <FormInput
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            {...register("password")}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          />
          <PasswordToggleIcon onClick={togglePasswordVisibility}>
            {showPassword ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </PasswordToggleIcon>
        </PasswordInput>
        <ErrorMsg variant="p">{errors?.password?.message}</ErrorMsg>
        <PasswordInput>
          <FormInput
            {...register("confirmPassword")}
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          />
          <PasswordToggleIcon onClick={toggleConfirmPasswordVisibility}>
            {showConfirmPassword ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </PasswordToggleIcon>
        </PasswordInput>
        <ErrorMsg variant="p">{errors?.confirmPassword?.message}</ErrorMsg>
        <AuthButton
          sx={{
            textTransform: "capitalize",
            bgcolor: "#009872ED",
            color: "white",

            "&:hover": {
              bgcolor: "#088464",
            },
          }}
          type="submit"
          //   disabled={!isValid}
          // loading={loading}
          // loadingIndicator="Loading…"
          variant="contained"
        >
          <span>Register</span>
        </AuthButton>
      </FormFieldsWrapper>

    </FormContainer>
  );
};

export default Register;
