import { Box, styled } from "@mui/material";

const SalesPlanOuterBox = styled(Box)({
  background: "#5593FE1A",
  padding: "20px", // Optional padding inside SD
  borderRadius: "8px",
  marginTop: "10px",
  //height for Display scaled at 100% and browser scaled at 100%
  "@media (min-resolution: 96dpi)": {
    height: "63.4vh",
  },
  //height for Display scaled at 100% and browser scaled at 110%
  "@media (min-resolution: 105.6dpi)": {
    height: "52.5vh",
  },
  //height for Display scaled at 100% and browser scaled at 125%
  "@media (min-resolution: 120dpi)": {
    height: "56.5vh",
  },
  /* height for Display scaled at 100% and browser scaled at 150% */
  "@media (min-resolution: 144dpi), (min-device-pixel-ratio: 1.5) ": {
    height: "52.5vh",
  },
  //height for Display scaled at 100% and browser scaled at 175%
  "@media (min-resolution: 168dpi)": {
    height: "47vh",
  },
  //height for Display scaled at 125% and browser scaled at 110%
  "@media (min-resolution: 132dpi)": {
    height: "55vh",
  },
  //height for Display scaled at 125% and browser scaled at 125%
  "@media (min-resolution: 150dpi)": {
    height: "51vh",
  },
  //height for Display scaled at 125% and browser scaled at 150%
  "@media (min-resolution: 180dpi)": {
    height: "43.5vh",
  },
});

export { SalesPlanOuterBox };
