import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import React from 'react';
// import {  useTheme } from "@mui/material";

const PresenceScore = (props) => {
  // const theme = useTheme();
  // const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <div style={{ display: 'flex',  justifyContent: 'center', alignItems:"center", width: '100%', height: '100%' }}>
      <Gauge
        value={props.score}
        minValue={0}
        maxValue={100}
        animationDuration={1000} // Animation duration in milliseconds
        radius={25} // Fixed outer radius of the gauge
        innerRadius={20} // Fixed inner radius to control thickness
        text={({ value }) => `${value}%`}
        sx={{
          // marginTop:"-12%",
          height: 40, // Fixed height
          width: 40,  // Fixed width
          fontSize: '12px',
          fontWeight: 'bold',
          [`& .${gaugeClasses.valueArc}`]: {
            fill: '#56BB6B',
            strokeWidth: 18, // Fixed arc width
          },
          [`& .${gaugeClasses.backgroundArc}`]: {
            stroke: '#e0e0e0', // Grey background color
            strokeWidth: 18, // Fixed arc width
          },
        }}
      />
    </div>
  );
};

export default PresenceScore;
