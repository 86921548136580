import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, IconButton } from "@mui/material";
import {
  IconContainer,
  NewTaskBtn,
  SettingBtn,
  StyledButton,
  TitleTypography,
} from "../../../Reusable/Header/HeaderStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { plusIconWhite } from "../../../Constants/Constant";
import { useNavigate } from "react-router-dom";

const CommonHeader = (props) => {
  const [projectName, setProjectName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem("role");

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(new Date());

    const actionFormat = `Action: ${formattedDate}`;
    if (role === "piqai_sdr") {
      setProjectName(actionFormat);
    } else {
      setProjectName(props.headerData);
    }
  }, [props]);

  const handleBack = () => {
    navigate("/piqual-ai/dashboard");
  };
  return (
    <>
      <Grid size={6}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconContainer>
            <IconButton sx={{ p: 0 }}>
              <ArrowBackIcon sx={{ fontSize: "28px" }} onClick={handleBack} />
            </IconButton>
            <TitleTypography variant="h6">{props.title}</TitleTypography>
          </IconContainer>

          <StyledButton active={true}>
            {" "}
            {projectName ? projectName : "New"}
          </StyledButton>
        </Box>
      </Grid>
      <Grid size={6}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <StyledButton>All Projects</StyledButton>
          {props.role !== "sdr" && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <SettingBtn>
                <SettingsOutlinedIcon sx={{ fontSize: "18px" }} />
                <span style={{ fontWeight: "500" }}>Settings</span>
              </SettingBtn>
              <NewTaskBtn>
                <img src={plusIconWhite} alt="" />
                <span style={{ fontWeight: "500" }}>New Project</span>
              </NewTaskBtn>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default CommonHeader;
