import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../global";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../utils/ToastContext";
import { setUserData } from "../../../Redux/Actions/userActions";
import { useAuth } from "../AuthContext/AuthContext";

const EULAPage = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const { login } = useAuth();
const {  token } = useParams();
  const userData = useSelector((state) => state?.sdrData?.sdrUserData);
  const [loading, setLoading] = useState(false);
  const agreeEULA = () => {
    const obj = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password,
      user_id: userData.user_id,
      email: userData.email,
      eula_accepted :true
    };
    fetch(`${API}/auth/invite-register`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("response", res);
        console.log("res.success", res.success);
        if (res.success) {
          const userData = res.result;
          dispatch(
            setUserData({
              firstName: userData.first_name,
              lastName: userData.last_name,
            })
          );
          localStorage.setItem("token", userData.token);
          localStorage.setItem("sas_url", userData.sas_token);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("role", userData.user_role_key);
          localStorage.setItem("first_name", userData.first_name);
          localStorage.setItem("last_name", userData.last_name);
          localStorage.setItem("chat_history", userData.chat_status);

          login(userData.token);
          if (userData.user_role_key === "piqai_sdr") {
            navigate("/piqual-ai/sdr-dashboard");
          } else {
            navigate("/piqual-ai/dashboard");
          }
        } else {
          showToast("error", `${res.error.msg}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };
  return (
    <>
      <Box
        sx={{
          border: "1px solid #9CA4BA66",
          borderRadius: "12px",
          height: "484px",
          width: "100%",
          maxWidth: "680px",
          letterSpacing: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "center",
          padding: "16px",
          boxSizing: "border-box",
          overflow: "hidden", // Hide overflowing content
        }}
      >
        <Typography
          variant="h6"
          sx={{
            wordWrap: "break-word",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "24px",
            color: "#009872",
            marginBottom: "16px",
          }}
        >
          End User License Agreement (EULA)
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
            padding: "0 8px",
            textAlign: "left",
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: "1.6", color: "#000" }}>
            <p>
              This End User License Agreement ("Agreement") is a legal contract
              between you ("User" or "Customer") and Piqual, with its principal
              place of business at Piqual ("Company"). By accessing or using
              Piqual AI (the "Platform"), you acknowledge that you have read,
              understood, and agree to be bound by the terms of this Agreement.
              If you do not agree with any part of this Agreement, you must not
              use the Platform.
            </p>

            <h2>1. Grant of License</h2>
            <p>
              1.1. Subject to your compliance with the terms of this Agreement,
              the Company grants you a limited, non-exclusive, non-transferable,
              and revocable license to access and use Piqual AI solely for B2B
              lead generation, including but not limited to automating sales
              development representative (SDR) tasks, streamlining lead
              generation, outreach, and qualification processes.
            </p>
            <p>
              1.2. The license is restricted to your internal business purposes
              and may not be used for resale, redistribution, or any purpose
              beyond those explicitly permitted in this Agreement.
            </p>

            <h2>2. Restrictions on Use</h2>
            <p>2.1. You shall not:</p>
            <ul>
              <li>
                Reverse engineer, decompile, disassemble, or otherwise attempt
                to discover the source code or underlying algorithms of the
                Platform.
              </li>
              <li>
                Rent, lease, sublicense, sell, or distribute access to the
                Platform.
              </li>
              <li>Use the Platform for any illegal or unauthorized purpose.</li>
              <li>
                Interfere with or disrupt the integrity or performance of the
                Platform.
              </li>
              <li>
                Share your access credentials or allow unauthorized users to
                access the Platform.
              </li>
            </ul>
            <p>
              2.2. Any violation of these restrictions will result in immediate
              termination of your license.
            </p>

            <h2>3. Data Usage and Privacy</h2>
            <p>
              3.1. The Platform may collect and process personal data and
              business information to provide its services. By using the
              Platform, you consent to the collection, use, and storage of data
              as described in the Company’s Privacy Policy.
            </p>
            <p>
              3.2. The Company will take reasonable measures to protect the
              confidentiality and security of data but shall not be liable for
              unauthorized access or breaches caused by third parties.
            </p>

            <h2>4. Intellectual Property</h2>
            <p>
              4.1. The Platform, including its features, functionalities, and
              underlying technology, is the intellectual property of the Company
              and is protected by copyright, trademark, and other applicable
              laws.
            </p>
            <p>
              4.2. All rights not expressly granted under this Agreement are
              reserved by the Company. You acquire no ownership or other rights
              in the Platform other than the limited license granted herein.
            </p>

            <h2>5. Disclaimer of Warranties</h2>
            <p>
              5.1. The Platform is provided "as is" and "as available" without
              any warranties, express or implied, including but not limited to
              merchantability, fitness for a particular purpose, or
              non-infringement.
            </p>
            <p>
              5.2. The Company does not guarantee that the Platform will meet
              your requirements, operate uninterrupted, or be error-free.
            </p>

            <h2>6. Limitation of Liability</h2>
            <p>
              6.1. To the maximum extent permitted by law, the Company shall not
              be liable for any indirect, incidental, consequential, or punitive
              damages, including loss of profits, data, or business
              opportunities, arising from your use of the Platform.
            </p>
            <p>
              6.2. The Company’s total liability under this Agreement shall not
              exceed the amount you paid for access to the Platform in the
              preceding twelve (12) months.
            </p>

            <h2>7. Termination</h2>
            <p>
              7.1. The Company reserves the right to terminate this Agreement
              and your access to the Platform immediately if you breach any
              terms of this Agreement.
            </p>
            <p>
              7.2. Upon termination, you must cease all use of the Platform and
              delete any related materials in your possession.
            </p>

            <h2>8. Governing Law and Jurisdiction</h2>
            <p>
              8.1. This Agreement shall be governed by and construed in
              accordance with the laws of [Jurisdiction].
            </p>
            <p>
              8.2. Any disputes arising out of or in connection with this
              Agreement shall be subject to the exclusive jurisdiction of the
              courts of [Jurisdiction].
            </p>

            <h2>9. Miscellaneous</h2>
            <p>
              9.1. This Agreement constitutes the entire agreement between you
              and the Company regarding the use of the Platform and supersedes
              all prior agreements, whether written or oral.
            </p>
            <p>
              9.2. If any provision of this Agreement is deemed unenforceable,
              the remaining provisions shall remain in full force and effect.
            </p>
            <p>
              9.3. The Company reserves the right to modify this Agreement at
              any time. Any changes will be communicated to you, and continued
              use of the Platform constitutes acceptance of the modified terms.
            </p>

            <p>
              For any questions or concerns regarding this Agreement, please
              contact [Insert Contact Information].
            </p>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Button
          onClick={agreeEULA}
          sx={{
            width: "100px",
            height: "48px",
            background: "#109D79 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            color: "white",
            textTransform: "capitalize",
            fontSize: "16px",
          }}
        >
          {loading ? "Loading.." : "I Agree"}
        </Button>
        <Button
          sx={{
            width: "100px",
            height: "48px",
            borderRadius: "4px",
            color: "#009872",
            border: "1px solid #109D79",
            textTransform: "capitalize",
            fontSize: "16px",
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default EULAPage;
