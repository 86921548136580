import { Box, Typography, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const MainAccountContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "700px",
  margin: "10px",
  gap: "20px",
});

const CustomBox = styled(Box)({
  border: "1px solid #DCDCDC",
  width: "100%",
  borderRadius: "4px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  boxSizing: "border-box"
});

const SubText = styled(Typography)({
  fontSize: "12px",
  color: "#ABABAB",
  fontWeight:"600"
});

const AccountInput = styled("input")({
  border: "1px solid #DCDCDC",
  borderRadius: "5px",
  color: "#150F36",
  padding: "7px",
  width: "100%",
  boxSizing: "border-box",

  "&:focus": {
    outline: "none",
  },

  "&::placeholder": {
    color: "#DCDCDC",
  },
});

const AccountBtn = styled(LoadingButton)({
  width: "fit-content",
  fontSize: "14px",
  textTransform: "capitalize",
  height: "30px",
  marginTop: "10px",

  "&:hover": {
    color: "white",
  },
});

const CustomModalBg = styled(Box)({
  padding: "20px",
  backgroundColor: "white",
  color: "black",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",

  borderRadius: "5px",
});

const ContentBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const Asterisk = styled("sup")({
  fontweight: "bold",
  fontSize: "12px",
  color: "red",
});

export {
  MainAccountContainer,
  CustomBox,
  SubText,
  AccountInput,
  AccountBtn,
  CustomModalBg,
  ContentBox,
  Asterisk,
};
