import { Box, Button, IconButton, styled, Typography } from "@mui/material";

const FormContainer = styled("form")(({ theme }) => ({
  width: "400px",
  padding: "10px",
  textAlign: "center",
}));

const FormInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "0 11px",
  borderRadius: "4px",
  backgroundColor: "#E9EFF6",
  border: "none",
  fontSize: "16px",
  color: "#707070",
  height: "48px",
  "&::placeholder": {
    color: "#868A97",
    fontSize: "12px",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: "#E9EFF6",
  },
  [theme.breakpoints.up("xl")]: {
    "&::placeholder": {
      fontSize: "1rem",
    },
  },
}));

const FormFieldsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "10px",
  width: "100%",
  marginTop: "20px"
}));

const PasswordInput = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const PasswordToggleIcon = styled(IconButton)(() => ({
  position: "absolute",
  right: "12px",
  cursor: "pointer",
  color: "#868A97",
  background: "none",
  border: "none",
}));

const AuthButton = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "#6249EF",
  color: "white",
  fontSize: "16px",
  height: "48px",
  marginTop: "10px",
  "&:hover": {
    backgroundColor: "#4E3BBC",
  },

  "&:disabled": {
    opacity: "70%",
  },
});

const ErrorMsg = styled(Typography)({
  color: "red",
  textAlign: "left",
  fontSize: "12px",
  fontWeight: "bold",
});

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  overflowY: "auto"
});

const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100%",
});

const ContainedBtn = styled(Button)(({ bgColor, hoverColor, theme, fontSize }) => ({
  backgroundColor: bgColor,
  border: `1px solid ${bgColor}`,
  color: "white",
  padding: "6px 12px",
  borderRadius: "4px",
  fontSize: fontSize,
  fontWeight: "500",
  textTransform: "none",
  "&:hover": {
    backgroundColor: hoverColor,
    border: `1px solid ${hoverColor}`,
  },
  "&:disabled": {
    opacity: "70%",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const OutlinedBtn = styled(Button)(({ btnColor, hoverColor, theme, fontSize }) => ({
  color: hoverColor,
  border: `1px solid ${hoverColor}`,
  padding: "6px 12px",
  borderRadius: "4px",
  textTransform: "none",
  fontSize: fontSize,
  fontWeight: "500",
  backgroundColor: btnColor,
  width:"40%",
  "&:hover": {
    backgroundColor: hoverColor,
    border: `1px solid ${hoverColor}`,
    color: "white"
  },
  "&:disabled": {
    opacity: "70%",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "93vh",
  boxSizing: "border-box",
  [theme.breakpoints.up("xl")]: {
    gap: "10px"
  },
}));

const ContentContainerPlanPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  boxSizing: "border-box",
  [theme.breakpoints.up("xl")]: {
    gap: "10px"
  },
}));


export {
  FormContainer,
  FormInput,
  ErrorMsg,
  PasswordInput,
  PasswordToggleIcon,
  FormFieldsWrapper,
  AuthButton,
  MainContainer,
  LoaderContainer,
  ContainedBtn,
  OutlinedBtn,
  FlexBox,
  ContentContainer,
  ContentContainerPlanPage
};
