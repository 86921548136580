import React from "react";
import { Box, Typography } from "@mui/material";
import { AccountBtn, CustomBox, SubText } from "./ProfilePageStyles";
import { useAuth } from "../Authentication/AuthContext/AuthContext";

const ManageAccountContainer = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  let disableDelete = false;
  return (
    <CustomBox>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{fontSize:"20px",fontWeight:"500"}}>Manage Account</Typography>
        <SubText>Log out or delete your account securely</SubText>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <AccountBtn
          sx={{
            border: "1px solid #009872ED",
            color: "#009872ED",
            "&:hover": {
              bgcolor: "#009872ED",
            },
          }}
          onClick={handleLogout}
        >
          Log Out
        </AccountBtn>
        <AccountBtn
          disabled={disableDelete}
          sx={{
            border: disableDelete ? "1px solid lightgrey" : "1px solid #EB0000",
            color: "#EB0000",
            "&:hover": {
              bgcolor: "#EB0000",
            },
          }}
        >
          Delete my account
        </AccountBtn>
      </Box>
    </CustomBox>
  );
};

export default ManageAccountContainer;
