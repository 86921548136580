import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import { ChartBox, InnerBox } from "../ActionItStyles";

const OutReachStatus = () => {
  const data = [
    { name: "Primary Email", value: 25 },
    { name: "Follow-up Email", value: 4 },
    { name: "Call", value: 37 },
    { name: "LinkedIn Message", value: 16 },
    { name: "LinkedIn Inmail", value: 18},
  ];

  // Define different colors for each bar
  const barColors = ["#99E3A3", "#F8A3A3", "#ADD9FF", "#FFD4A6", "#C4B8ED"];

  return (
    <InnerBox>
      <ChartBox style={{ width: "100%", height: 200 }}>
        <ResponsiveContainer>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 5, right: 40, left: 20, bottom: 5 }} // Adjusted left margin for more space
          >
            <CartesianGrid strokeDasharray="0 0 0" vertical={true} horizontal={false} />
            <XAxis type="number" tickCount={6} domain={[0, 50]} />
            <YAxis
              type="category"
              dataKey="name"
              width={100} // Set appropriate width for the labels
              tick={({ x, y, payload }) => {
                return (
                  <text
                    x={x - 10} // Position adjusted to make space
                    y={y + 5} // Vertical adjustment for better alignment
                    fill="#333" // Label color
                    fontSize="12"
                    fontFamily="Roboto"
                    textAnchor="end" // Proper alignment for labels
                    style={{
                      overflow: "visible",
                      whiteSpace: "nowrap",
                      maxWidth: "100px", // Ensure labels are not too long
                    }}
                  >
                    {payload.value}
                  </text>
                );
              }}
            />
            <Tooltip />
            <Bar dataKey="value">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={barColors[index % barColors.length]} // Assign different colors for each bar
                />
              ))}
              <LabelList
                dataKey="value"
                position="right" // Position value labels at the right of bars
                style={{ fontSize: 12, fill: "#000" }} // Adjust label appearance
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ChartBox>
    </InnerBox>
  );
};

export default OutReachStatus;
