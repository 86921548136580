import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import ChatBoxInterface from "./ChatBoxInterface";
import AIEngine from "../../ActionIt/Status/AIEngine";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InnerBox, TitleBox } from "../../ActionIt/ActionItStyles";
import OutReachStatus from "../../ActionIt/Status/OutReachStatus";
import ActivityTable from "../../ActionIt/Status/ActivityTable";
import CommonHeader from "./CommonHeader";

const ActionItLayout = () => {
  const [expanded, setExpanded] = useState([
    // "aiEngine",
    "outReachStatus",
    "activityTable",
  ]);

  const getActivityHeight = () => {
    const isAIEngineOpen = expanded.includes("aiEngine");
    const isOutReachOpen = expanded.includes("outReachStatus");

    if (!isAIEngineOpen && !isOutReachOpen) {
      return 675; // Both collapsed and ActivityTable is directly expanded
    }

    if (isAIEngineOpen && isOutReachOpen) {
      return 263; // Both are expanded
    }

    if (isAIEngineOpen && !isOutReachOpen) {
      return 520; //when AIEngineOpen
    }

    return 424; // At least one is open
  };

  const handleAccordionToggle = (panel) => {
    setExpanded((prevExpanded) => {
      const isAIEngineOpen = prevExpanded.includes("aiEngine");
      const isOutReachOpen = prevExpanded.includes("outReachStatus");

      // Directly expand ActivityTable
      if (panel === "activityTable") {
        if (isAIEngineOpen || isOutReachOpen) {
          // If either AI Engine or OutReach is open, collapse them and expand ActivityTable
          return ["activityTable"];
        } else {
          // If both are collapsed, open the other two panels and set ActivityTable height to 313px
          return ["aiEngine", "outReachStatus", "activityTable"];
        }
      }

      // Toggle behavior for AI Engine and OutReach Status
      return prevExpanded.includes(panel)
        ? prevExpanded.filter((item) => item !== panel)
        : [...prevExpanded, panel];
    });
  };
  return (
    <Box container sx={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        columns={{ xs: 4, md: 12 }}
        columnSpacing={{ xs: 1, sm: 2, md: 1 }}
      >
        <CommonHeader title={"Action"} role={"sdr"} />
        <Grid sx={{ height: "88vh" }} size={6}>
          <Box
            sx={{
              border: "1px solid #00987233",
              backgroundColor: "#56BB6B0D",
              borderRadius: "8px",
              height: "100%", // Outer box height
              display: "flex", // Enable flexbox
              flexDirection: "column", // Stack children vertically
            }}
          >
            {/* Explicit height for #testData */}
            <Box
              id="testData"
              sx={{
                height: "96%",
                margin: 1, // Apply consistent margins
              }}
            >
              <ChatBoxInterface />
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ height: "88vh" }} size={6}>
          {/* Flexbox Layout for Boxes */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            {/* Box W */}

            {/* AI Engine Accordion */}

            <Accordion
              expanded={expanded.includes("aiEngine")}
              onChange={() => handleAccordionToggle("aiEngine")}
              sx={{
                backgroundColor: "#FF7D341A",
                borderRadius: "8px",
                marginTop: "0px",
                transition: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ai-engine-content"
                id="ai-engine-header"
              >
                <TitleBox sx={{ marginLeft: "3px" }}>AI Engine</TitleBox>
                {!expanded.includes("aiEngine") && (
                  <InnerBox
                    sx={{
                      mx: "auto",
                      fontFamily: "Victor Mono",
                      width: "80%",
                      overflowY: "auto",
                      overflowX: "hidden",
                      height: "1rem",
                    }}
                  >
                    <AIEngine />
                  </InnerBox>
                )}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "10px",
                  overflow: "hidden",
                  marginTop: "-8px",
                  maxHeight: "240px",
                }}
              >
                {expanded.includes("aiEngine") && (
                  <InnerBox
                    sx={{
                      fontFamily: "Victor Mono",
                      width: "96%",
                      overflowY: "auto",
                      overflowX: "hidden",
                      height: "100px",
                      margin: "-8px 5px 14px 9px",
                    }}
                  >
                    <AIEngine />
                  </InnerBox>
                )}
              </AccordionDetails>
            </Accordion>

            {/* OutReach Status Accordion */}
            <Accordion
              expanded={expanded.includes("outReachStatus")}
              onChange={() => handleAccordionToggle("outReachStatus")}
              sx={{
                backgroundColor: "#5593FE1A",
                borderRadius: "10px",
                transition: "none",
                marginTop: "12px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="outreach-status-content"
                id="outreach-status-header"
              >
                <TitleBox>Your Outreach Status So Far</TitleBox>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "10px",
                  overflow: "hidden",
                  marginTop: "-8px",
                  maxHeight: "240px",
                }}
              >
                <InnerBox
                  sx={{
                    fontFamily: "monospace",
                    width: "96%",
                    overflowY: "hidden",
                    height: "185px",
                    margin: "-8px 5px 14px 9px",
                  }}
                >
                  <OutReachStatus />
                </InnerBox>
              </AccordionDetails>
            </Accordion>

            {/* Activity Table Accordion */}
            <Accordion
              expanded={expanded.includes("activityTable")}
              onChange={() => handleAccordionToggle("activityTable")}
              sx={{
                backgroundColor: "#8348CF1A",
                borderRadius: "10px",
                transition: "none",
                marginTop: "12px",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="activity-table-content"
                id="activity-table-header"
              >
                <TitleBox>Activities Scheduled For Today</TitleBox>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "10px",
                  overflowX: "hidden",
                }}
              >
                <InnerBox
                  sx={{
                    width: "96%",
                    margin: "-8px 5px 7px 9px",
                    marginBottom: "30px",
                    padding: "8px",
                    overflowY: "hidden",
                    overflowX: "hidden",
                    borderRadius: "10px",
                    // maxHeight: `${getActivityHeight()}px`,
                    // backgroundColor: "blue",
                    height: `${getActivityHeight() - 39}px`,
                    //Hieght for Display scaled at 125%
                    "@media (min-resolution: 120dpi)": {
                      height: `${getActivityHeight() - 220}px`,
                    },
                    //Hieght for Display scaled at 150%
                    "@media (min-resolution: 144dpi)": {
                      height: `${getActivityHeight() - 350}px`,
                    },
                  }}
                >
                  <ActivityTable height={getActivityHeight()} />
                </InnerBox>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActionItLayout;
