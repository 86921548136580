import React, { useEffect, useRef, useState } from "react";

import { Typography } from "@mui/material";

import { InnerBox, OuterBox, TitleBox } from "../../ActionIt/ActionItStyles";
import { useSelector } from "react-redux";

const AIEngine = () => {
  const projectData = useSelector((state) => state.projectDetails.aiEngineLogs);
  const aiEngineRef = useRef(null);
  const [highlightedItems, setHighlightedItems] = useState([]);

  useEffect(() => {
    if (aiEngineRef.current) {
      aiEngineRef.current.scrollTop = aiEngineRef.current.scrollHeight;
    }
  }, [projectData]);

  useEffect(() => {
    // Determine the new items by comparing the current list with previously highlighted items
    const newItems =
      projectData?.filter((item) => !highlightedItems.includes(item)) || [];
    if (newItems.length > 0) {
      setHighlightedItems((prev) => [...prev, ...newItems]);

      // Remove highlight after 3 seconds
      setTimeout(() => {
        setHighlightedItems((prev) =>
          prev.filter((item) => !newItems.includes(item))
        );
      }, 3000);
    }

    // eslint-disable-next-line
  }, [projectData]);
  return (
    <OuterBox bgColor="#FF7D341A" height="fit-content">
      <TitleBox variant="h6">AI Engine</TitleBox>
      <InnerBox
        sx={{
          fontFamily: "monospace",
          overflowY: "auto",
          height: "100px",
        }}
        ref={aiEngineRef}
      >
        {projectData?.map((data, index) => (
          <Typography
            key={data?.action}
            sx={{
              fontSize: "14px",
              // color: highlightedItems.includes(data) ? "red" : "#00A67D",
              color: "#00A67D",
            }}
          >
            {" "}
            {data?.action}
          </Typography>
        ))}
      </InnerBox>
    </OuterBox>
  );
};

export default AIEngine;
