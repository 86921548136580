import React, { useState, useEffect } from "react";
import { API } from "../../../global";
import {
    AuthButton,
    ErrorMsg,
    FormContainer,
    FormFieldsWrapper,
    FormInput,
    PasswordInput,
    PasswordToggleIcon,
} from "../../../Common/CommonStyles";
import { Box } from "@mui/material";
import { closesEye, openEye } from "../../../Constants/Constant";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext/AuthContext.js";
import { useForm } from "react-hook-form";

const schema = yup.object().shape({
    password: yup
        .string()
        .required("Password is a required field.")
        .min(8, "Password must be at least 8 characters.")
        .max(20, "Password must be at most 20 characters.")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
        .matches(/\d/, "Password must contain at least one numeric digit.")
        .matches(
            /[@$!%*?&]/,
            "Password must contain at least one special character."
        ),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match.")
        .required("Password is a required field."),
});


function ResetPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

    const { id, token } = useParams();
    const navigate = useNavigate();
    const { login } = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        localStorage.setItem('token', token)
    }, [token])
    const onSubmit = async (data) => {
        setLoading(true); 
        const payload = {
            forget_password_key: id,
            password: data.password,
        };
        try {
            const response = await fetch(`${API}/auth/reset-password`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            if (result.success) {
                login(token);
                navigate("/");
            } else {
                console.error("Error:", result.message || "Password reset failed.");
            }
        } catch (error) {
            console.error("Error:", error.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)} >
            <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
                Let's Get Started
            </Box>
            <FormFieldsWrapper>
                <PasswordInput>
                    <FormInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter New Password"
                        {...register("password")}

                    />
                    <PasswordToggleIcon onClick={togglePasswordVisibility}>
                        {showPassword ? (
                            <img src={openEye} alt="" />
                        ) : (
                            <img src={closesEye} alt="" />
                        )}
                    </PasswordToggleIcon>
                </PasswordInput>
                <ErrorMsg>{errors?.password?.message}</ErrorMsg>
                <PasswordInput>
                    <FormInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm Password Password"
                        {...register("confirmPassword")}
                        error={errors?.confirmPassword?.message}

                    />
                    <PasswordToggleIcon onClick={togglePasswordVisibility}>
                        {showPassword ? (
                            <img src={openEye} alt="" />
                        ) : (
                            <img src={closesEye} alt="" />
                        )}
                    </PasswordToggleIcon>
                </PasswordInput>
                 <ErrorMsg>{errors?.confirmPassword?.message}</ErrorMsg>


                <AuthButton
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: "#009872ED",
                        color: "white",
                        "&:hover": {
                            bgcolor: "#088464",
                        },
                    }}
                    type="submit"
                    disabled={loading}
                    variant="contained"
                >
                    <span>Submit</span>
                </AuthButton>
            </FormFieldsWrapper>
        </FormContainer>
    )
}

export default ResetPassword