import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { Avatar, Box, Typography, Paper } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { FaMicrophone } from "react-icons/fa";

import {
  InputBox,
  InputContainer,
  LoadingButton,
  MessageContainer,
  PinButton,
  SendButton,
  SuggestionButton,
  // SuggestionButton,
  TextContainer,
} from "../../PlanIt/PlanItStyles";
import { buddyWhitebackground, pin, send } from "../../../Constants/Constant";
import { stringAvatar, useToast } from "../../../utils/ToastContext";
import { startConversation } from "../../../Redux/Actions/projectAction";
import { API } from "../../../global";
import {
  getAIEngineLogsData,
  getConversationDetails,
  getMetricsDetails,
  getProjectDetailsData,
  resetProjectDetails,
  setChatData,
  setCurrentQuestionData,
} from "../../../Redux/Actions/aiChatActions";

const ChatBoxInterface = ({ onSendData }) => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const prevConversationRef = useRef();

  const [panelHeading, setPanelHeading] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listening, setListening] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [suggestionShow, setSuggestionShow] = useState(false);
  // const [conversationDetails, setConversationDetails] = useState(null);

  const userRole = localStorage.getItem("role");
  const chatWindowRef = useRef(null);

  //Redux data
  const { firstName, lastName } = useSelector((state) => state.user);
  const conversation = useSelector(
    (state) => state.startConversation.projectData
  );
  const projectDataState = useSelector((state) => state.projectDetails);
  const currentQuestionData = projectDataState.currentQuestionData;
  useEffect(() => {
    getProjectId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      prevConversationRef?.current?.result?.project_id ===
      conversation?.result?.project_id
    ) {
      return; // Skip logic if the conversation hasn't changed
    }

    prevConversationRef.current = conversation;

    if (conversation?.success) {
      setLoading(false);
      const project = conversation.result;
      dispatch(getConversationDetails(project));
      if (
        !project.conversation_history ||
        project.conversation_history.length === 0
      ) {
        // setConversationDetails(project);
        // const chatObj = {
        //   message: "",
        //   question: "",
        //   conversation_code: project?.conversation_code,
        //   project_id: project?.project_id,
        //   question_id: "",
        // };
        setMessageList([]);
        // setLoading(true);

        // getQuestions(chatObj);
      } else {
        dispatch(setCurrentQuestionData(project?.conversation_history[0]));
        let historyList = [];
        project.conversation_history.forEach((data) => {
          const historyObj = {
            msg: data.response,
            type: data.message_from,
          };
          historyList.push(historyObj);
        });
        setMessageList(historyList);
        getProjectDetails(project?.project_id);
      }
    }

    // eslint-disable-next-line
  }, [conversation]);

  const getDomainName = (url) => {
    // Normalize the URL (handle different formats)
    let domain = url?.replace(/^https?:\/\//, ""); // Remove 'http://' or 'https://'
    domain = domain?.replace(/^www\./, ""); // Remove 'www.'

    // Extract company name (first part of the domain)
    const name = domain?.split(".")[0]; // Splitting by '.' and getting the first part
    return name?.charAt(0)?.toUpperCase() + name?.slice(1); // Capitalize first letter
  };

  useEffect(() => {
    return () => {
      // Dispatch the reset action when the component unmounts
      dispatch(resetProjectDetails());
      if (userRole !== "piqai_sdr") {
        onSendData("");
      }
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messageList]);

  const getProjectId = () => {
    dispatch(startConversation({ new_project: true }))
      .then((data) => {
        const project = data.result;
        const chatObj = {
          message: "",
          question: "",
          conversation_code: project?.conversation_code,
          project_id: project?.project_id,
          question_id: "",
        };
        setMessageList([]);
        setLoading(true);
        getQuestions(chatObj);
        return () => {
          // Dispatch the reset action when the component unmounts
          dispatch(resetProjectDetails());
          dispatch(getAIEngineLogsData());
        };
      })
      .catch((error) => {
        console.error("Dispatch failed", suggestionShow);
      });
  };

  const handleClick = () => {
    setListening(!listening);
  };

  const getQuestions = (chatObj) => {
    setLoading(true);
    localStorage.setItem("loading", true);
    chatObj.role = localStorage.getItem("role");

    fetch(`${API}/chat/conversation`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(chatObj),
    })
      .then((response) => response.json())
      .then((res) => {
        localStorage.setItem("loading", false);
        if (res.success) {
          getProjectDetails(chatObj.project_id);
          getAIEngineLog(chatObj.project_id);
          getMetricsValue(chatObj.project_id);
          const question = res.result;
          setCurrentQuestion(question);
          dispatch(setCurrentQuestionData(question));
          if (question.answer_suggestions) {
            setSuggestionShow(true);
          }
          let questionId = ''
          if(userRole === 'piqai_sdr'){
            if(messageList.length === 0){
              questionId =1
            }
          }
          let queValue = "";
          if (
            questionId === 1 ||
            question.question_category === "sdr_get_task"
          ) {
            queValue =
            questionId === 1 ||
            questionId === "sdr_get_task"
                ? `${conversation?.result?.response} \n${question.response}`
                : question.response;
          }
          const chatResponse = {
            msg: question.question_order ||question.question_category === "sdr_get_task"
                ? queValue
                : question.response,
            type: question.message_from,
            acknowledgeComment: question.acknowledge_comment,
            answer_suggestions: question.answer_suggestions,
            suggestionClicked: false,
          };
          setMessageList((prevMessageList) => [
            ...prevMessageList,
            chatResponse,
          ]);
          // getProjectDetails(chatObj.project_id);
        } else {
          showToast("error", "An error occurred. Please try again.");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const clickSuggestion = (item, index) => {
    localStorage.setItem("inputKey", "added");
    const obj = {
      msg: item,
      type: "user",
    };
    setMessageList((prevMessageList) => [...prevMessageList, obj]);
    const chatObj = {
      message: item,
      question: currentQuestionData.response,
      conversation_code: currentQuestionData.conversation_code,
      project_id: currentQuestionData.project_id,
      question_id: currentQuestionData.question_id,
      question_category: currentQuestionData.question_category,
      needs_confirmation: currentQuestionData.needs_confirmation,
    };

    if (messageList[index]) {
      messageList[index].suggestionClicked = true;
    }

    getQuestions(chatObj);
    setSuggestionShow(false);
    setInputMessage("");

    // getProjectDetails(chatObj.project_id);
  };

  const getProjectDetails = (projectId) => {
    fetch(`${API}/project/get-project-details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId }),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);

        if (res.success) {
          console.log("res.result", res.result);
          if (res.result) {
            const domainNameData = getDomainName(res.result.domain);

            console.log("domainNameData", domainNameData);
            if (domainNameData) {
              onSendData(domainNameData);
              setPanelHeading(domainNameData);
            }
          }

          dispatch(getProjectDetailsData(res.result));
          dispatch(setChatData(currentQuestion));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMetricsValue = (projectIdData) => {
    fetch(`${API}/project/get-project-metrics`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectIdData }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(getMetricsDetails(res.result));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAIEngineLog = (projectId) => {
    const projectIdData = projectId;

    fetch(`${API}/project/get-ai-action-logs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectIdData }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(getAIEngineLogsData(res.result));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      // Prevent default behavior (e.g., form submission)
      event.preventDefault();

      // Add a new line to the input message
      setInputMessage((prevMessage) => {
        const updatedMessage = prevMessage + "\n";

        // Dynamically adjust textarea height
        const textarea = event.target; // The textarea element triggering the event
        handleTextareaResize(textarea);

        // Scroll to the bottom to show the new line
        setTimeout(() => {
          textarea.scrollTop = textarea.scrollHeight; // Scroll to the bottom
        }, 0);

        return updatedMessage;
      });
    } else if (event.key === "Enter") {
      // Prevent default behavior
      event.preventDefault();

      // Hide suggestions and send the message
      // setSuggestionShow(false);
      handleInputMessage();
    }
  };

  const handleInputMessage = () => {
    localStorage.setItem("inputKey", "added");
    if (inputMessage) {
      const obj = {
        msg: inputMessage,
        type: "user",
      };
      setMessageList((prevMessageList) => [...prevMessageList, obj]);
      const chatObj = {
        message: inputMessage,
        question: currentQuestionData.response,
        conversation_code: currentQuestionData.conversation_code,
        project_id: currentQuestionData.project_id,
        question_id: currentQuestionData.question_id,
        question_category: currentQuestionData.question_category,
        needs_confirmation: currentQuestionData.needs_confirmation,
      };
      setLoading(true);
      getQuestions(chatObj);
      // getAIEngineLog(chatObj.project_id);
      // getMetricsValue(chatObj.project_id);
      setInputMessage("");
      setSuggestionShow(false);
    }

    // Reset textarea height
    const textarea = document.querySelector("textarea");
    if (textarea) {
      textarea.style.height = "auto"; // Reset to default height
    }
  };

  const handleTextareaResize = (textarea) => {
    // Reset height to auto first to calculate correct scrollHeight
    textarea.style.height = "auto";
    // Set the height based on its scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;

    textarea.style.padding = "0px";
    textarea.style.padding = `${textarea.padding}px`;
  };

  return (
    <>
      {/* <ChatBox className="chatBox"> */}
      <Box
        sx={{
          flexShrink: 0, // Prevent this box from growing or shrinking
          // marginBottom: "10px", // Add spacing below Typography
          height: "5%",
        }}
      >
        {userRole === "piqai_sdr" ? (
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: "18px", marginBottom: "10px" }}
          >
            Action : Hit The Ground Running!
          </Typography>
        ) : (
          <>
            {panelHeading && (
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Project:{panelHeading}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "95%",
        }}
      >
        <MessageContainer ref={chatWindowRef}>
          {messageList.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: msg?.type === "AI" ? "row" : "row-reverse", // AI on left, user on right
                alignItems: "flex-start", // Ensure vertical alignment
                my: 1, // Add vertical spacing between messages
                position: "relative", // Make this box the reference for absolute positioning
              }}
            >
              {/* AI Avatar or User Avatar */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: 40,
                  height: 40,
                  mr: msg?.type === "AI" ? 2 : 0, // Margin-right for AI
                  ml: msg?.type === "AI" ? 0 : 2, // Margin-left for User
                }}
              >
                {msg?.type === "AI" ? (
                  <img
                    src={buddyWhitebackground}
                    alt="AI Avatar"
                    style={{
                      width: "36px",
                      height: "36px",
                      objectFit: "contain",
                      marginTop: "-6px",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      mt: "-6px",
                    }}
                    {...stringAvatar(firstName, lastName)}
                  />
                )}
              </Box>

              {/* Message Content */}
              <Box
                sx={{
                  textAlign: msg?.type === "AI" ? "left" : "right", // Align text based on message type
                  maxWidth: "70%", // Limit message width for readability
                  background: msg?.type === "AI" ? " #ffff " : "#00A67D",
                  borderRadius:
                    msg?.type === "AI" ? "0px 8px 8px 8px" : "8px 0px 8px 8px",
                  border: "1px solid #9CA4BA66",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    font: "normal normal bold 16px/19px Roboto",
                    letterSpacing: "0px",
                    color: msg?.type === "AI" ? "#0D162F" : "#ffff",
                    opacity: 1,
                    lineHeight: "19px",
                    margin: "5px",
                  }}
                >
                  {msg?.type === "AI" ? "Sales Buddy" : "You"}
                </Typography>

                <Paper
                  sx={{
                    mt: 0.5,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    p: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: msg?.type === "AI" ? "#707070" : "#ffff",
                      font: "normal normal normal 16px/22px Roboto",
                      letterSpacing: "0px",
                      opacity: 1,
                      margin: "10px",
                      lineHeight: 1.5,
                      textAlign: "justify",
                    }}
                  >
                    {msg?.acknowledgeComment ? (
                      <ReactMarkdown
                        components={{
                          pre: ({ node, ...props }) => (
                            <Box
                              component="pre"
                              sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                              {...props}
                            />
                          ),
                        }}
                      >
                        {msg?.acknowledgeComment}
                      </ReactMarkdown>
                    ) : (
                      <ReactMarkdown
                        components={{
                          pre: ({ node, ...props }) => (
                            <Box
                              component="pre"
                              sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                              {...props}
                            />
                          ),
                        }}
                      >
                        {msg?.msg}
                      </ReactMarkdown>
                    )}
                  </Typography>
                </Paper>

                {/* Suggestion Button Positioned at the Bottom */}
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%", // Center horizontally
                    transform: "translateX(-50%)", // Adjust for centering
                    display: "flex",
                    marginTop:"10px",
                    gap: 1,
                  }}
                >
                  {suggestionShow && (
                    <>
                      {!msg.suggestionClicked && (
                        <>
                          {msg?.answer_suggestions && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                              gap={1}
                            >
                              {msg?.answer_suggestions
                                ?.split(",")
                                ?.map((item) => (
                                  <SuggestionButton
                                    key={item}
                                    onClick={() => clickSuggestion(item, index)}
                                    bgColor="#009872"
                                  >
                                    {item}
                                  </SuggestionButton>
                                ))}
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          ))}

          {loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end", // Push content to the bottom
                alignItems: "center", // Center horizontally
                height: "100%", // Ensure the parent box takes full height
                margin: "5px",
                gap: 1,
              }}
            >
              <LoadingButton sx={{ px: 1 }}>
                <div
                  style={{
                    textAlign: "Center",
                    marginRight: "10px",
                    marginTop: "5px",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="15"
                    width="15"
                    color="#009872"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
                Analysing
              </LoadingButton>
            </Box>
          )}
        </MessageContainer>

        <InputContainer>
          <InputBox>
            <button
              type="button"
              className={`mic ${listening ? "listening" : ""}`}
              onClick={handleClick}
            >
              <FaMicrophone size={16} />
            </button>
            <PinButton>
              <img src={pin} alt="" style={{ width: "16px" }} />
            </PinButton>
          </InputBox>
          <TextContainer
            rows="1"
            type="text"
            value={inputMessage}
            placeholder="Send a message"
            onInput={(e) => {
              handleTextareaResize(e.target); // Dynamically adjust height
            }}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            inputMessage={inputMessage}
          />{" "}
          <SendButton onClick={handleInputMessage}>
            <img src={send} alt="" style={{ width: "16px" }} />
          </SendButton>
        </InputContainer>
      </Box>

      {/* // </ChatBox> */}
    </>
  );
};

export default ChatBoxInterface;
