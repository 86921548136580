import React, { createContext, useState, useContext } from "react";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const showToast = (severity, message) => {
    setToast({ open: true, severity, message });
  };

  const closeToast = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <ToastContext.Provider value={{ toast, showToast, closeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export const stringAvatar = (firstName, lastName) => {
  let firstNameData = firstName ? firstName?.split(" ")[0][0] : "";
  let lastNameData = lastName ? lastName?.split(" ")[0][0] : "";
  return {
    sx: {
      background: "#FF7D34",
      color: "white",
      width: "36px",
      height: "36px",
      fontSize: "15px",
      marginRight: "17px",
    },
    children: `${firstNameData}${lastNameData}`,
  };
};
