import { Box, Button, IconButton, styled, Typography } from "@mui/material";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px"
  },
}));

const IconContainer = styled(Box)({
  display: "flex",
  gap: "10px",

});

const TabContainer = styled(Box)(({ theme, actionIt, planIt }) => ({
  display: "flex",
  gap: "14px",
  marginRight: actionIt
    ? "80px" 
    : planIt
    ? "-70px"
    : "-70px",
  // [theme.breakpoints.between(1200, 1536)]: {
  //   marginRight: actionIt
  //     ? "80px" 
  //     : planIt
  //     ? "-70px"
  //     : "-70px",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   marginRight: actionIt
  //     ? "140px" 
  //     : planIt
  //     ? "-100px"
  //     : "-100px",
  // },
}));



const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  width: "175px",
}));

const StyledButton = styled(Button)(({ active }) => ({
  borderBottom: active ? `2px solid #00aa8c` : "none",
  backgroundColor: active ? "#e7f5f2" : "#eaedf1",
  color: active ? "#00a17f" : "#a7b1c3",
  height: "28px",
  fontSize: "16px",
  fontWeight: active ? 500 : 400,
  // width: "96px",
  textTransform: "capitalize",
}));

const SettingBtn = styled(IconButton)({
  border: "1px solid #009872ED",
  borderRadius: "5px",
  height: "32px",
  padding: "5px 10px",
  textTransform: "capitalize",
  fontSize: "14px",
  display: "flex",
  gap: "7px",
  color: "#009872ED",

  "&:hover": {
    backgroundColor: "#009872ED",
    color: "white",
  },
});

const NewTaskBtn = styled(IconButton)({
  border: "1px solid #009872ED",
  borderRadius: "5px",
  height: "32px",
  padding: "5px 10px",
  textTransform: "capitalize",
  fontSize: "14px",
  display: "flex",
  gap: "7px",
  color: "white",
  backgroundColor: "#009872",

  "&:hover": {
    backgroundColor: "#008463",
    color: "white",
  },
});

export {
  TitleTypography,
  HeaderContainer,
  IconContainer,
  TabContainer,
  StyledButton,
  SettingBtn,
  NewTaskBtn
};