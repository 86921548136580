import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import ChatBoxInterface from "./ChatBoxInterface";
import AIEngine from "../../PlanIt/SalesPlan/AIEngine";
import SalesPlanBox from "./SalesPlanBox";
import CommonHeader from "./CommonHeader";
import { SalesPlanOuterBox } from "../Css/MainLayout_Css";

const MainLayout = () => {
  const [title, setTitle] = useState("");
  const headingData = (data) => {
    setTitle(data);
  };
  return (
    <Box container sx={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        columns={{ xs: 4, md: 12 }}
        columnSpacing={{ xs: 1, sm: 2, md: 1 }}
      >
        <CommonHeader title={"Plan"} headerData={title} />
        <Grid sx={{ height: "88vh" }} size={6}>
          <Box
            sx={{
              border: "1px solid #00987233",
              backgroundColor: "#56BB6B0D",
              borderRadius: "8px",
              height: "100%", // Outer box height
              display: "flex", // Enable flexbox
              flexDirection: "column", // Stack children vertically
            }}
          >
            <Box
              sx={{
                // height: "calc(93% - 24px)", // Ensure it doesn't exceed the parent (subtract margins)
                margin: 1, // Apply consistent margins
                height: "96%",
              }}
            >
              <ChatBoxInterface onSendData={headingData} />
            </Box>
          </Box>
        </Grid>

        <Grid sx={{ height: "88vh" }} size={6}>
          {/* Flexbox Layout for Boxes */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              paddingBottom: "20px",
              // backgroundColor:"red",
            }}
          >
            {/* Box W */}
            <Box
              sx={{
                height: "calc(30%-4px)", // Subtract gap size
                marginBottom: "4px", // Gap between w and sd
              }}
            >
              <AIEngine />
            </Box>

            {/* Box SD */}
            <SalesPlanOuterBox>
              {/* Flexbox Layout for Inner Content */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {/* <SalePlan clientDetails={null} /> */}
                <SalesPlanBox />
              </Box>
            </SalesPlanOuterBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainLayout;
