import {
  Box,
  styled,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
} from "@mui/material";

const TitleBox = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "roboto",

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const ChartBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const OuterBoxPlanPage = styled(Box)(({ bgColor, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: bgColor,
  padding: '20px',
  borderRadius: '8px',
  boxSizing: "border-box",
  height:"100%",

  [theme.breakpoints.up("xl")]: {
    gap: "20px"
  },
}));

const OuterBox = styled(Box)(({ bgColor, theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  backgroundColor: bgColor,
  padding: "16px",
  borderRadius: "8px",
  boxSizing: "border-box",
  paddingBottom: "20px",
  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  padding: "2px 0px 10px 8px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "10px",
  border: "1px solid #DDDEE0",
  backgroundColor: "#ffffff",
  width: "99%",
  marginTop: "8px",
});

const StyledTableHead = styled(TableHead)({
  border: "1px solid #006EED1A",
});

const StyledTableCellHead = styled(TableCell)({
  fontWeight: "500",
  padding: "8px",
  fontSize: "14px",
  backgroundColor: "#8348CF1A",
});

const StyledTableCell = styled(TableCell)({
  padding: "10px",
  fontSize: "14px",
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#9CA4BA",
  "&.Mui-checked": {
    color: "#009872",
  },
  width: "16px",
  height: "16px",
  "& svg": {
    fontSize: "16px",
  },
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // backgroundColor: "red",

  height: "98.6%",
  width: "54%",
  // [theme.breakpoints.up("md")]: {
  //   flex: "1 1 49.5%",
  // },
  // [theme.breakpoints.up("xl")]: {
  //   gap: "0px",
  // },
}));

const StatusContainerPlanPage = styled(Box)(({ theme }) => ({

  display: 'flex',
  flexDirection: 'column',
  height: "80vh",
  gap: '10px',
  flex: 0.5,
  [theme.breakpoints.up("md")]: {
    flex: "0 0 48%",
  },
  [theme.breakpoints.up("xl")]: {
    gap: "20px"
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  height: "160px",
  width: "200px",
  [theme.breakpoints.up("xl")]: {
    height: "200px",
  },
}));

const ChatBox = styled(Box)({
  flex: "0 0 48%",
  border: "1px solid #00987233",
  backgroundColor: "#56BB6B0D",
  borderRadius: "8px",
  padding: "20px",
  position: "relative",
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "15px",
  height: "85vh",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const MainContainerPlanPage = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  // height: "85vh",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
}));

const InputContainer = styled(Box)({
  position: "absolute",
  border: "1px solid #C9C9C9",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%",
  bottom: "20px",
});

const MessageContainer = styled(Box)({
  minHeight: "80%",
  minWidth: "90%",
  overflowY: "auto",
  // whiteSpace: "pre-wrap", // Preserves whitespace and ensures wrapping
  // overflowWrap: "break-word", // Allows text to wrap inside the box
  paddingBottom: "10px",
});

export {
  TitleBox,
  OuterBox,
  InnerBox,
  ChartBox,
  StyledTableCell,
  StyledTableCellHead,
  StyledTableContainer,
  StyledTableHead,
  StyledCheckbox,
  StatusContainer,
  ChartContainer,
  ChatBox,
  MainContainer,
  InputContainer,
  MessageContainer,
  MainContainerPlanPage,
  StatusContainerPlanPage,
  OuterBoxPlanPage
};
