import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { jwtDecode } from "jwt-decode";
import { Box } from "@mui/material";
import Cookies from "js-cookie";

// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import { API } from "../../../global";

import {
  AuthButton,
  ErrorMsg,
  FormContainer,
  FormFieldsWrapper,
  FormInput,
  PasswordInput,
  PasswordToggleIcon,
} from "../../../Common/CommonStyles";
import {
  // AccountText,
  ForgotPasswordLink,
  // LinkTag,
  // SignupText,
} from "./LoginStyles";
import { useAuth } from "../AuthContext/AuthContext";
import { useToast } from "../../../utils/ToastContext";
// In the imports of Login component
import { useDispatch } from "react-redux";
import { setUserData } from "../../../Redux/Actions/userActions";
import { closesEye, openEye } from "../../../Constants/Constant";

const schema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email."),
  password: yup.string().required("Password is a required field."),
});

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const Obj = {
      email: data.email.toLowerCase(),
      password: data.password,
    };
    // API Integration

    fetch(`${API}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          const userData = response.result;
          dispatch(
            setUserData({
              firstName: userData.first_name,
              lastName: userData.last_name,
            })
          );
          const decodedToken = jwtDecode(userData.token);
          const tokenExpiryDate = new Date(decodedToken.exp * 1000);

          Cookies.set("token", userData.token, { expires: tokenExpiryDate });
          localStorage.setItem("token", userData.token);
          localStorage.setItem("sas_url", userData.sas_token);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("role", userData.user_role_key);
          localStorage.setItem("first_name", userData.first_name);
          localStorage.setItem("last_name", userData.last_name);
          localStorage.setItem("chat_history", userData.chat_status);
          localStorage.setItem('last_login_date', userData.last_login_date ? userData.last_login_date : '')

          login(userData.token);
          if (userData.user_role_key === 'piqai_sdr') {
            navigate("/piqual-ai/sdr-dashboard");
          } else {
            navigate("/piqual-ai/dashboard");
          }
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
        Let's Get Started
      </Box>
      <FormFieldsWrapper>
        <FormInput
          sx={{ width: "94%" }}
          placeholder="Enter Email Address"
          {...register("email")}
        />
        <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg>

        <PasswordInput>
          <FormInput
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            {...register("password")}
          // onCopy={(e) => e.preventDefault()}
          // onPaste={(e) => e.preventDefault()}
          // onCut={(e) => e.preventDefault()}
          />
          <PasswordToggleIcon onClick={togglePasswordVisibility}>
            {showPassword ? (
              <img src={openEye} alt="" />
            ) : (
              <img src={closesEye} alt="" />
            )}
          </PasswordToggleIcon>
        </PasswordInput>
        <ErrorMsg>{errors?.password?.message}</ErrorMsg>

        <ForgotPasswordLink to="/forgot-password">
          Forgot Password?
        </ForgotPasswordLink>

        <AuthButton
          sx={{
            textTransform: "capitalize",
            bgcolor: "#009872ED",
            color: "white",
            "&:hover": {
              bgcolor: "#088464",
            },
          }}
          type="submit"
          disabled={loading}
          variant="contained"
        >
          <span>{loading ? "Loading..." : "Login"}</span>
        </AuthButton>
      </FormFieldsWrapper>
    </FormContainer>
  );
};

export default Login;
