import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  AuthButton,
  FormContainer,
  FormFieldsWrapper,
  FormInput,
} from "../../../Common/CommonStyles";
import { ForgotPasswordLink } from "../Login/LoginStyles";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPassword = () => {
  const [checkEmail, setCheckEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast(); 


  const schema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is a required field.")
      .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email.")
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

 
  const onSubmit = (data) => {
    setLoading(true);
    const requestBody = { email: data.email.toLowerCase() };

    
    fetch(`${API}/auth/send-reset-link`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setCheckEmail(true);
          showToast("success", "A password reset link has been sent to your email.");
        } else {
          showToast("error", response.error?.msg || "Something went wrong. Please try again.");
        }
      })
      .catch(() => {
        setLoading(false);
        showToast("error", "An error occurred. Please try again.");
      });
  };

  return (
    <>
      {checkEmail ? (
        <FormContainer sx={{ width: "max-content" }}>
          <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
            Check Your Email!
          </Box>
          <p>We just sent you an email with a link to reset your password</p>
        </FormContainer>
      ) : (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
            Forgot Your Password
          </Box>
          <p>Please enter your registered email address</p>
          <FormFieldsWrapper>
            <FormInput
              sx={{ width: "94%" }}
              placeholder="Enter Email Address"
              {...register("email")}
            />
            {errors.email && (
              <p style={{ textAlign: "left", color: "red", fontSize: "14px" }}>{errors.email.message}</p>
            )}
            <AuthButton
              sx={{
                textTransform: "capitalize",
                bgcolor: "#009872ED",
                color: "white",
                "&:hover": {
                  bgcolor: "#088464",
                },
              }}
              type="submit"
              disabled={loading} 
              variant="contained"
            >
              <span>{loading ? "Loading..." : "Request Reset Link"}</span>
            </AuthButton>
            <ForgotPasswordLink to="/" sx={{ textAlign: "center", mt: 1 }}>
              Back to Login Screen
            </ForgotPasswordLink>
          </FormFieldsWrapper>
        </FormContainer>
      )}
    </>
  );
};

export default ForgotPassword;
